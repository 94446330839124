body {
  height: 100vh;
  margin: 0;
}

.streamHost .zego_model_parent {
  border-radius: 0px;
}
.zego_model_parent {
  border-radius: 16px;
  min-width: 100px;
  background-color: #1c1b20;
}
.dIzgYQV4CBbzZxzJbwbS {
  background-color: #1c1b20;
}
#ZegoRoomFooter {
  background-color: #1c1b20;
}
.streamHost #ZegoRoomFooter {
  background-color: rgba(110, 113, 132, 0.1);
}
.aUBcrib1jsrHTK9vhlVZ {
  display: none;
}
.qJdF_iTl1gv6JkFZmE2R {
  display: none;
}
#ZegoRoomLeaveButton {
  display: none;
}
#ZegoRoomMoreButton {
  display: none;
}
//shared link
.QbarJSxzhe6iPp6VdlAs {
  display: none;
}


@font-face {
  font-family: 'Dela Gothic One';
  src: url(../assets/fonts/DelaGothicOne-Regular.ttf) format("truetype")
}




// .slick-list {
//   height: 100%; /* Убедитесь, что высота установлена */
//   touch-action: pan-y; /* Ограничение свайпа только по вертикали */
//   overflow: hidden; /* Скрытие лишних частей */
// }

// .slick-track {
//   display: flex;
//   flex-direction: column; /* Вертикальная ориентация */
// }
.vertical-slider {
  display: flex;
  flex-direction: column; /* Делаем слайды вертикальными */
  height: 100vh;
  overflow-y: scroll; /* Включаем вертикальный скроллинг */
  scroll-snap-type: y mandatory;
}

.vertical-slider .awssld__content {
  height: 100%; /* Задаем высоту для каждого слайда */
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}

.vertical-slider .awssld__wrapper {
  overflow-y: auto; /* Вертикальное движение */
}

.R5UUhzsZdRHH4U5V6kon {
  display: none !important;
}

.QAHxuJxRZWb3P_cbR8QA {
  margin: 0 !important;
  width: 100% !important;
  border-radius: 0px !important;
}

.lRNsiz_pTf7YmA5QMh4z {
  border-radius: 0px;
}

.pVo6XoSfywa4eLk9ef2S {
  background: #1c1b20;
  border-radius: 0px;
}

.bMIVaiZVnicrlBNGAN_y {
  display: none !important;
}

.BK8wLjgIh8fTQu2lLdir {
  display: none !important;
}

.PutMpvQbQ8ffCLR93qIq {
  display: none !important;
}

.sKtK1LFA_jOcb1MuqFNo {
  position: absolute !important;
  bottom: 0px !important;
  display: flex !important;
  justify-content: center !important;
  gap: 16px;
}

.L6vIrmD0q9g5Yu2h6ofV {
  display: none;
}

.PKUs48dx6H82gxtbJh7D {
  display: none;
}

.streamUser .zego_model_parent {
  height: calc(100vh - 220px);
}

.streamUser .dIzgYQV4CBbzZxzJbwbS{
  display: none !important;
}

.streamUser #ZegoRoomFooter{
  display: none !important;
}

.streamUser .CTk5yvs1VFTUR3zVNAE8{
  display: none !important;
}

.streamUser .yZuGKtil1LUofPm3MP8R{
  display: none !important;
}

.streamUser>div{
  border-radius: 16px;
}

@media (max-width: 600px) {
 .streamUser .zego_model_parent {
  height: 350px !important;
}

.streamUser>div{
  border-radius: 0px;
}
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}
.custom-table th, .custom-table td {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 16px 8px;
  text-align: left;
}
.custom-table tr:first-child th {
  border-top: none;
}
.custom-table tr:last-child td {
  border-bottom: none;
}

 .videoContainer>video{
   object-fit: cover;
 }
